.page {
  background-color: #022c43;
  color: #ffd700;
  min-height: 100vh;
}
.page-content .container {
  opacity: 0;
  z-index: 1;
  animation: fadeIn 0s forwards;
  animation-delay: 0s;
  overflow: hidden;
}

h1 {
  font-size: min(3vw, 75px);
  color: #fff;
  font-family: "Coolvetica";
  font-weight: 400;
}

h2 {
  color: #8d8d8d;
  margin-top: 20px;
  font-weight: 400;
  font-size: min(3vw, 25px);
  font-family: sans-serif;
  letter-spacing: 3px;
  animation: fadeIn 1s linear both;
  animation-delay: 2s;
}

@media only screen and (max-width: 600px) {
  h1 {
    font-size: 20px;
  }
  h2 {
    font-size: 15px;
  }
}
