#myNavbar {
  background-color: #181818 !important;
}

#navbarToggler {
  background-color: #ffd700 !important;
}

#navbarBrand {
  color: #ffd700 !important;
}

.me-auto a {
  color: #ffd700 !important;
  margin-left: 40px;
  text-decoration: none;
  i {
    transition: all 0.3s ease-out;
  }

  &:hover {
    color: #ffd700;
    svg {
      opacity: 0;
    }
    &:after {
      opacity: 0.7;
    }
  }

  &:after {
    content: "";
    margin-left: -35px;
    letter-spacing: 2px;
    position: absolute;
    justify-content: center;
    opacity: 0;
    transition: all 0.3s ease-out;
  }
}
a.home-link {
  &:after {
    content: "HOME";
  }
}
a.about-link {
  &:after {
    content: "ABOUT";
  }
}
a.blog-link {
  &:after {
    content: "BLOG";
  }
}
a.resume-link {
  &:after {
    content: "RÉSUMÉ";
  }
}

a.side-projects-link {
  &:after {
    content: "PROJECTS";
  }
}

a.active {
  svg {
    color: #ffd700;
  }
}
