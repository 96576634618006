#resume {
  color: #fff;
}

p {
  margin-bottom: 0;
}

ul {
  margin-top: 10px;
}
