#subtitle {
  color: rgba(255, 255, 255, 0.3);
  color: #ffd900ae;
  animation: fadeIn 1s linear both;
  animation-delay: 5.2s;
}

.fa-linkedin,
.fa-instagram,
.fa-twitter {
  color: white;
}

.fa-linkedin:hover {
  color: #0a66c2;
}

.fa-twitter:hover {
  color: #1da1f2;
}

.fa-instagram:hover {
  color: #e4405f;
}

#profilePic img {
  animation: fadeIn 1s linear both;
  animation-delay: 3.5s;
}

#profilePic img {
  width: 80%;
  height: auto;
}
